/* eslint-disable no-console */

import { register } from 'register-service-worker';

console.log('origin = ', window.location.origin);

const isProduction = process.env.NODE_ENV === 'production';

const isFlutterWebview = /FlutterWebview/i.test(navigator.userAgent);
const isAndroidWebview = /AndroidWebview/i.test(navigator.userAgent);
const isWebview = isFlutterWebview || isAndroidWebview;

console.log(`isProduction = ${isProduction}, isWebview = ${isWebview}`);

if (isProduction && !isWebview) {
  register(`${window.location.origin}/service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated() {
      console.log('New content is available; please refresh.');
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
