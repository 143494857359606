import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from 'vue-router';
import {
  CONFIG,
  CONSTANTS,
  IS_DEVELOPMENT,
} from '@/helpers';
import {
  useStore,
} from '@/store';

const isGuest = {
  meta: {
    isGuest: true,
  },
};

const isCommon = {
  meta: {
    isCommon: true,
  },
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "common-landing-page" */ '../views/Common/LandingPage.vue'),
    meta: {
      title: 'Daily Stocks',
      ...isGuest.meta,
    },
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/AuthView.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "auth-login" */ '../views/Auth/LoginView.vue'),
        meta: {
          title: 'Login',
          ...isGuest.meta,
        },
      },
      {
        path: 'signup',
        name: 'SignUp',
        component: () => import(/* webpackChunkName: "auth-signup" */ '../views/Auth/SignupView.vue'),
        meta: {
          title: 'Sign Up',
          ...isGuest.meta,
        },
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "auth-reset-password" */ '../views/Auth/ResetPassword.vue'),
        meta: {
          title: 'Reset Password',
          ...isGuest.meta,
        },
      },
      {
        path: 'verify-reset-password/:id',
        name: 'VerifyResetPassword',
        component: () => import(/* webpackChunkName: "auth-verify-reset-password" */ '../views/Auth/VerifyResetPassword.vue'),
        meta: {
          title: 'Verify Reset Password',
          ...isGuest.meta,
        },
      },
      {
        path: 'activate-email',
        name: 'ActivateEmail',
        component: () => import(/* webpackChunkName: "auth-activate-email" */ '../views/Auth/ActivateEmail.vue'),
        meta: {
          title: 'Activate Email',
          ...isGuest.meta,
        },
      },
      {
        path: 'verify-email/:token',
        name: 'VerifyEmail',
        component: () => import(/* webpackChunkName: "auth-verify-email" */ '../views/Auth/VerifyEmail.vue'),
        meta: {
          title: 'Verify Email',
          ...isGuest.meta,
        },
      },
    ],
  },
  {
    path: '/login/google',
    name: 'GoogleLogin',
    component: () => import(/* webpackChunkName: "auth-google-login" */ '../views/Auth/GoogleLogin.vue'),
    meta: {
      title: 'Google Login',
      ...isGuest.meta,
    },
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import(/* webpackChunkName: "common-privacy-policy" */ '../views/Common/PrivacyPolicy.vue'),
    meta: {
      title: 'Privacy Policy',
      ...isCommon.meta,
    },
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfService',
    component: () => import(/* webpackChunkName: "common-terms-of-service" */ '../views/Common/TermsOfService.vue'),
    meta: {
      title: 'Terms of Service',
      ...isCommon.meta,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "non-admin-dashboard" */ '../views/NonAdmin/DashboardView.vue'),
    meta: {
      title: 'Dashboard',
    },
  },
  {
    path: '/portfolios',
    name: 'Portfolio',
    component: () => import(/* webpackChunkName: "non-admin-portfolios" */ '../views/NonAdmin/PortfolioView.vue'),
    meta: {
      title: 'Portfolio',
    },
  },
  {
    path: '/portfolios/edit/:type/:id',
    name: 'MutatePortfolio',
    component: () => import(/* webpackChunkName: "non-admin-mutate-portfolio" */ '../views/NonAdmin/MutatePortfolio.vue'),
    meta: {
      title: 'Edit Portfolio',
    },
  },
  {
    path: '/stock-choice/add|edit/:id?',
    name: 'MutateStockChoice',
    component: () => import(/* webpackChunkName: "non-admin-mutate-stock-choice" */ '../views/NonAdmin/MutateStockChoice.vue'),
    meta: {
      title: 'Add/Edit Stock Choice',
    },
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "non-admin-contact" */ '../views/NonAdmin/ContactView.vue'),
    meta: {
      title: 'Contact',
    },
  },
  {
    path: '/user',
    name: 'User',
    component: () => import(/* webpackChunkName: "non-admin-user" */ '../views/NonAdmin/UserView.vue'),
    children: [
      {
        path: 'account',
        name: 'Account',
        component: () => import(/* webpackChunkName: "non-admin-user-account" */ '../views/NonAdmin/AccountView.vue'),
        meta: {
          title: 'Account',
        },
      },
    ],
  },
  {
    path: '/admin',
    name: 'AdminTemplate',
    component: () => import(/* webpackChunkName: "admin" */ '../views/Admin/AdminTemplate.vue'),
    children: [
      {
        path: 'user-list',
        name: 'UserList',
        component: () => import(/* webpackChunkName: "admin-user-list" */ '../views/Admin/UserList.vue'),
        meta: {
          title: 'User List',
        },
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "admin-mutate-settings" */ '../views/Admin/MutateSettings.vue'),
        meta: {
          title: 'Settings',
        },
      },
      {
        path: 'email-events',
        name: 'EmailEvents',
        component: () => import(/* webpackChunkName: "admin-email-events" */ '../views/Admin/EmailEvents.vue'),
        meta: {
          title: 'Email Events',
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// ensuring user load from local storage attempted before routing
router.beforeEach(async (to, from, next) => {
  const prevRoutePath = from.fullPath;
  await useStore.app.init(prevRoutePath);
  next();
});

router.beforeResolve((to, from, next) => {
  const authStore = useStore.auth;

  const isAdminUser = authStore.user?.role === 'admin';
  const isNonAdminUser = authStore.user?.role === 'non-admin';
  const isGuestUser = !authStore.user;

  const titleSuffix = !isGuestUser && IS_DEVELOPMENT
    ? ` | ${authStore.user?.firstName}`
    : '';

  if (!to.meta || !to.meta.title) {
    document.title = `${CONFIG.appName}${titleSuffix}`;
  } else {
    document.title = `${to.meta.title}${titleSuffix}`;
  }

  if (isGuestUser && !to.meta?.isGuest && !to.meta?.isCommon) {
    next(
      `/auth/${
        from.meta?.isCommon ? 'signup' : 'login'
      }?redirect=${encodeURIComponent(to.fullPath)}`,
    );
    return;
  }

  if (to.fullPath.startsWith('/admin')) {
    if (isAdminUser) {
      next();
    } else {
      next(CONSTANTS.ROUTES.ADMIN_HOME);
    }
    return;
  }

  if (isAdminUser) {
    // if logged, admin goes to admin home page
    next(CONSTANTS.ROUTES.ADMIN_HOME);
    return;
  }

  const isGuestPath = to.meta?.isGuest;

  if (isNonAdminUser && isGuestPath) {
    next(CONSTANTS.ROUTES.MEMBER_HOME);
    return;
  }

  next();
});

export { routes };
export default router;
