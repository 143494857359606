/*
 * Ankur Mursalin
 *
 * https://encryptioner.github.io/
 *
 * Created on Thu Aug 04 2022
 */

import axios, {
  AxiosResponse,
} from 'axios';
import {
  defineStore,
} from 'pinia';
import {
  abbreviateName,
  profileImageSrc,
  handleAxiosError,
} from '@/helpers';
import {
  User,
  LoginInfo,
  GoogleLoginInfo,
  FacebookLoginInfo,
} from '@/type';

async function getLoggedInUser(token: string): Promise<User | undefined> {
  try {
    const resp = await axios.get('/users/this', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return resp.data.user;
  } catch (e: any) {
    console.error(e);
    return undefined;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const useAuthStore = defineStore('auth', {
  state: () => ({
    user: undefined as User | undefined,
    token: '',
  }),
  getters: {
    usernameAbbreviation: (state) => abbreviateName(state.user),
    userProfileImageSrc: async (state) => {
      const profileImage = await profileImageSrc(state.user);
      return profileImage;
    },
  },
  actions: {
    async refreshUser() {
      const user = await getLoggedInUser(this.token);
      this.user = user;
    },
    setAuthInfo(token: string, user?: User) {
      this.user = user;
      this.token = token;

      if (window.localStorage.getItem('TABBED_REMEMBER_ME')) {
        window.sessionStorage.setItem('token', token);
      } else {
        window.localStorage.setItem('token', token);
      }
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    },
    async login(token: string) {
      const user = await getLoggedInUser(token);
      this.setAuthInfo(token, user);
    },
    async localLogin() {
      const tabbedRememberMe = window.localStorage.getItem('TABBED_REMEMBER_ME');
      const token = tabbedRememberMe
        ? window.sessionStorage.getItem('token')
        : window.localStorage.getItem('token');
      if (!token) {
        return;
      }

      const user = await getLoggedInUser(token);
      this.setAuthInfo(token, user);
    },
    async googleLogin({
      code,
      credential,
      referredCode,
    }: GoogleLoginInfo) {
      try {
        let response: AxiosResponse<LoginInfo>;

        if (credential) {
          response = await axios.post<LoginInfo>(
            '/auth/login/google/one-tap',
            {
              credential,
              referredCode,
            },
          );
        } else {
          response = await axios.post<LoginInfo>(
            '/auth/login/google/oauth2',
            {
              code,
              referredCode,
            },
          );
        }

        const { user, token } = response.data;
        this.setAuthInfo(token, user);
      } catch (e: any) {
        handleAxiosError(e);
      }
    },
    async fbLogin({
      token: fbToken,
      id,
      email,
      firstName,
      lastName,
      profileImage,
      referredCode,
    }: FacebookLoginInfo) {
      try {
        const response = await axios.post<LoginInfo>('/auth/login/fb', {
          token: fbToken,
          id,
          email,
          firstName,
          lastName,
          profileImage,
          referredCode,
        });
        const { user, token } = response.data;
        this.setAuthInfo(token, user);
      } catch (e: any) {
        handleAxiosError(e);
      }
    },
    logout() {
      this.setAuthInfo('', undefined);
    },
  },
  persist: {
    storage: window.sessionStorage,
  },
});
