/**
 * Ragib Un Nur
 * ragib@nerddevs.com
 * Created on Mon Jun 20 2022
 */

import Shepherd from 'shepherd.js';
import 'shepherd.js/dist/css/shepherd.css';
import {
  RouteRecordName,
} from 'vue-router';
import {
  CONSTANTS,
} from '@/helpers';

interface PopperOption {
  modifiers: [{
    name: 'offset',
    options: {
      offset: number[];
    }
  }]
}

export interface ITourStepProperties {
  // tour dom id, it will point to the shephard modal
  id: string;
  // the dom id or class of the attached element for which the tour is showing
  attachedElement: string;
  title: string;
  text: string;
  on: Shepherd.Step.PopperPlacement;
  firstButtonAction: 'Exit' | 'Back';
  secondButtonAction: 'Next' | 'Done';
  offset?: number[];

}

export class TourService {
  static createAndStartTour(
    tourStepProperties: ITourStepProperties[],
    localStorageKey: string,
  ): void {
    const tourLocalStorageField = localStorageKey;

    const isTourShown = !!window.localStorage.getItem(tourLocalStorageField);
    if (isTourShown) {
      return;
    }
    window.localStorage
      .setItem(tourLocalStorageField, new Date().toISOString());
    const tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: 'tour-step',
        cancelIcon: {
          enabled: true,
        },
        scrollTo: {
          behavior: 'smooth',
          block: 'center',
        },
      },
    });
    try {
      for (const stepProperty of tourStepProperties) {
        if (stepProperty.attachedElement.trim().charAt(0) !== '#'
          && stepProperty.attachedElement.trim().charAt(0) !== '.') {
          throw new Error(`attach element is neither a id or a class for ${stepProperty.attachedElement}`);
        }
        if (stepProperty.attachedElement.indexOf(' ') >= 0) {
          throw new Error(`attach element contains space ${stepProperty.attachedElement}`);
        }
        let offsetPopperOption: PopperOption | undefined;

        if (stepProperty.offset) {
          offsetPopperOption = {
            modifiers: [{
              name: 'offset',
              options: {
                offset: stepProperty.offset,
              },
            }],
          };
        }

        tour.addStep({
          id: stepProperty.id,
          title: stepProperty.title,
          text: stepProperty.text,
          attachTo: {
            element: stepProperty.attachedElement,
            on: stepProperty.on,
          },
          buttons: [
            {
              action(): void {
                return stepProperty.firstButtonAction === 'Exit' ? this
                  .cancel() : this.back();
              },
              secondary: true,
              text: stepProperty.firstButtonAction,
            },
            {
              action(): void {
                return stepProperty.secondButtonAction === 'Next' ? this
                  .next() : this.cancel();
              },
              text: stepProperty.secondButtonAction,
            },
          ],
          popperOptions: offsetPopperOption,
        });
      }
    } catch (e: any) {
      // eslint-disable-next-line no-console
      console.error(e);
      window.localStorage.removeItem(localStorageKey);
    }

    tour.start();
  }

  static restartTourForComponent(
    componentName: RouteRecordName | undefined | null,
    stockChoiceLength: number,
  ): void {
    const { TOURS } = CONSTANTS;
    if (!componentName) {
      // eslint-disable-next-line no-console
      console.error('component name cannot be found in removeTourFromComponent()');
      return;
    }
    if (typeof componentName === 'symbol') {
      // eslint-disable-next-line no-console
      console.error('component name type is symbol. Currently it is not supported in the feature');
      return;
    }

    if (componentName.toLocaleLowerCase() === 'dashboard') {
      TOURS.dashboard.forEach((v) => window.localStorage.removeItem(v));
      this.showDashboardLandingTour(stockChoiceLength);
    } else if (componentName.toLocaleLowerCase() === 'account') {
      TOURS.account.forEach((v) => window.localStorage.removeItem(v));
      this.startAccountTour();
    } else if (componentName.toLocaleLowerCase() === 'mutatestockchoice') {
      TOURS.stockChoiceAddForm
        .forEach((v) => window.localStorage.removeItem(v));
      this.startAddFormTour();
    }
  }

  static startAddFormTour(): void {
    const tourStepProperties: ITourStepProperties[] = [
      {
        id: 'trading-code-field-step',
        attachedElement: '.trading-code-field',
        title: 'Trading code',
        text: '<p>Code for the stock you are interested in</p>',
        on: 'bottom' as Shepherd.Step.PopperPlacement,
        firstButtonAction: 'Exit',
        secondButtonAction: 'Next',
      },
      {
        id: 'low-price-field-step',
        attachedElement: '.low-price-field',
        title: 'Target Buy Price',
        text: '<p>Lowest price of the stock you want to get alert for</p>',
        on: 'bottom' as Shepherd.Step.PopperPlacement,
        firstButtonAction: 'Back',
        secondButtonAction: 'Next',
      },
      {
        id: 'high-price-field-step',
        attachedElement: '.high-price-field',
        title: 'Target Sell Price',
        text: '<p>Highest price of the stock you want to get alert for</p>',
        on: 'bottom' as Shepherd.Step.PopperPlacement,
        firstButtonAction: 'Back',
        secondButtonAction: 'Next',
      },
      {
        id: 'enable-alert-switch-step',
        attachedElement: '.enable-alert-switch',
        title: 'Enable alert',
        text: '<p>To get browser notification for your selected stock</p>',
        on: 'bottom' as Shepherd.Step.PopperPlacement,
        firstButtonAction: 'Back',
        secondButtonAction: 'Done',
      }];

    TourService.createAndStartTour(tourStepProperties, 'FORM_TOUR');
  }

  static showDashboardLandingTour(stockChoiceLength: number): void {
    const termsButton = !!document.getElementById('footer-terms');

    const tourStepProperties: ITourStepProperties[] = [
      {
        id: 'view-title-step',
        attachedElement: '#view-title',
        title: 'Welcome',
        text: '<p>Daily stock gives you stock notification</p>',
        on: 'bottom' as Shepherd.Step.PopperPlacement,
        firstButtonAction: 'Exit',
        secondButtonAction: 'Next',
      },
      {
        id: 'add-stock-choice-step',
        attachedElement: '#add-stock-choice-btn',
        title: 'Add stock choice',
        text: '<p>Add stock choice from here</p>',
        on: 'bottom' as Shepherd.Step.PopperPlacement,
        firstButtonAction: 'Back',
        secondButtonAction: 'Next',
      }];
    if (!stockChoiceLength) {
      tourStepProperties.push({
        id: 'stock-choice-table-step',
        attachedElement: '#stock-choice-table',
        title: 'Stock choice table',
        text: '<p>Your choice stocks will show here</p>',
        on: 'bottom' as Shepherd.Step.PopperPlacement,
        firstButtonAction: 'Back',
        secondButtonAction: termsButton ? 'Next' : 'Done',
      });
    } else {
      tourStepProperties.push(
        {
          id: 'trading-code-value-step',
          attachedElement: '#trading-code-value',
          title: 'Trading Code',
          text: '<p>Click on the stock name to find detailed information about the stock</p>',
          on: 'top' as Shepherd.Step.PopperPlacement,
          firstButtonAction: 'Exit',
          secondButtonAction: 'Next',
        },
        {
          id: 'stock-alert-switch-step',
          attachedElement: '#stock-alert-switch',
          title: 'Enable/Disable Stock Alert',
          text: '<p>Toggle this switch to enable or disable notification for this stock choice</p>',
          on: 'top' as Shepherd.Step.PopperPlacement,
          firstButtonAction: 'Back',
          secondButtonAction: 'Next',
        },
        {
          id: 'edit-stock-step',
          attachedElement: '#edit-icon',
          title: 'Edit',
          text: '<p>Click this pencil icon to edit your chosen stock</p>',
          on: 'top' as Shepherd.Step.PopperPlacement,

          firstButtonAction: 'Back',
          secondButtonAction: 'Next',
        },
        {
          id: 'delete-stock-step',
          attachedElement: '#delete-icon',
          title: 'Delete',
          // eslint-disable-next-line max-len
          text: '<p>Don\'t want to get alert for a particular stock? Click the trash icon to remove it from the dashboard</p>',
          on: 'top' as Shepherd.Step.PopperPlacement,

          firstButtonAction: 'Back',
          secondButtonAction: 'Next',
        },
      );
    }

    if (termsButton) {
      tourStepProperties.push({
        id: 'footer-terms-step',
        attachedElement: '#footer-terms',
        title: 'Learn more',
        text: '<p>Learn more from terms</p>',
        on: 'top' as Shepherd.Step.PopperPlacement,
        firstButtonAction: 'Back',
        secondButtonAction: 'Done',
      });
    }

    TourService.createAndStartTour(tourStepProperties, 'DASHBOARD_LANDING_TOUR');
  }

  static startAccountTour(): void {
    const passwordField = !!document.getElementById('password-field');
    const tourStepProperties: ITourStepProperties[] = [
      {
        id: 'edit-profile-step',
        attachedElement: '#edit-profile-button',
        title: 'Edit',
        text: '<p>Edit your account settings from here</p>',
        on: 'bottom' as Shepherd.Step.PopperPlacement,
        firstButtonAction: 'Exit',
        secondButtonAction: 'Next',
        offset: [0, 20],
      },
      {

        id: 'stock-news-step',
        attachedElement: '#stock-news-checkbox',
        title: 'Stock News',
        text: '<p>Keep this checkbox checked to get news about your selected stocks</p>',
        on: 'bottom' as Shepherd.Step.PopperPlacement,
        firstButtonAction: 'Back',
        secondButtonAction: passwordField ? 'Next' : 'Done',
      },
    ];

    if (passwordField) {
      tourStepProperties.push({
        id: 'password-field-step',
        attachedElement: '#password-field',
        title: 'Change Password',
        text: '<p>You can change your password from here</p>',
        on: 'bottom' as Shepherd.Step.PopperPlacement,
        firstButtonAction: 'Back',
        secondButtonAction: 'Done',
      });
    }

    TourService.createAndStartTour(tourStepProperties, 'ACCOUNT_TOUR');
  }
}
